import NonAuthLayout from "../_warbls/Layout/NonAuthLayout"
import * as React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { Main } from "./modules/Main"
import { useSelector } from "react-redux"
import { Layout } from "../_warbls/Layout"
import "react-perfect-scrollbar/dist/css/styles.css"
import { BaseRoutes } from "./BaseRoutes"
import { actions } from "../redux/auth/actions"
import { actions as trackActions } from "../redux/track/actions"
import { useDispatch } from "react-redux"
import { Vocals } from "./modules/Vocals"
import { QA } from "./modules/QA"
import { ContactUs } from "./modules/ContactUs"
import  Plan from "./modules/Plan"
import SessionTimeout from "./SessionTimeout"
import { useLocation } from "react-router"
import { ForgotPassword } from "./modules/ForgotPassword"
import { ResetPassword } from "./modules/ResetPassword"
import { TermsAddStepper } from "./modules/Terms/index"
import { TermsOfUse } from "./modules/Terms/UpdatedTerms"
import CopylinkVocal from "./modules/CopylinkVocal"
import { GoogleOAuthProvider } from '@react-oauth/google';

import { SongContext } from "../_warbls/components/SideBarInfo"
import { DiscordRedirect } from "./modules/Discord/redirect"
import { ReferralsFriend } from "./modules/referrals"

const { useEffect, useState } = React

export var sidebarDrawerContext = React.createContext(null)


function App() {
  const { auth } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [collapse, isCollapsed] = useState(true)
  const location = useLocation()

  const [currentSong, setCurrentSong] = useState("Nosong");
  const [containerSong, setContainerSong] = useState(null);
  const [playing, setPlaying] = useState(false)
  const [listTrack, setListTrack] = useState('')
  const [currentId, setCurrentId] = useState(null)
  const [songIds, setSongIds] = useState('')
  
  useEffect(() => {
    // dispatch(actions.usersRequest())
    // dispatch(trackActions.removePlayingVocal())
  }, [dispatch, location])

  return (
    <>
      <GoogleOAuthProvider clientId="1008337831638-1858du5na98caeekibjbq6a2fgle3sui.apps.googleusercontent.com">
      <SongContext.Provider value={
        {currentSong,
         setContainerSong,
         containerSong,
         setCurrentSong,
          playing,
          setPlaying,
          listTrack,
          setListTrack,
          currentId,
          setCurrentId,
          songIds,
          setSongIds
        }}>
      <Switch>
        {!auth && (
          
          <>
            <Route path="/main" component={Main} />
            <Route path="/" component={Main} exact />
            <Route path="/termsOfUse" component={TermsOfUse} exact />
            <Route path="/referrals/:id" component={() => {
                return (
                    <ReferralsFriend />
                )
              }} exact/>
            <Route
              path="/vocals"
              component={() => {
                return (
                  <NonAuthLayout>
                    <Vocals titleLeft="10%" />
                  </NonAuthLayout>
                )
              }}
            />
            <Route
              path="/shareVocal/:id"
              component={() => {
                return (
                  <NonAuthLayout>
                    <CopylinkVocal/>
                  </NonAuthLayout>
                )
              }}
              exact
            />

            <Route path="/terms" component={TermsAddStepper} />

            <Route
              path="/q&a"
              component={() => {
                return (
                  <NonAuthLayout>
                    <QA titleLeft="10%" />
                  </NonAuthLayout>
                )
              }}
            />

            <Route
              path="/forgot"
              component={() => {
                return (
                  <NonAuthLayout>
                    <ForgotPassword />
                  </NonAuthLayout>
                )
              }}
              exact
            />

            <Route
              path="/reset/:hash"
              component={() => {
                return (
                  <NonAuthLayout>
                    <ResetPassword />
                  </NonAuthLayout>
                )
              }}
            />

            <Route
              path="/contact-us"
              component={() => {
                return (
                  <NonAuthLayout>
                    <ContactUs titleLeft="10%" />
                  </NonAuthLayout>
                )
              }}
            />
            <Route
              path="/discord-redirect"
              component={() => {
                return (
                  <NonAuthLayout>
                    <DiscordRedirect />
                  </NonAuthLayout>
                )
              }}
            />
            
            
          </>
          
        )}

        {!auth ? (
          <>
            <Redirect to="/" from="/" />
          </>
        ) : (
          <sidebarDrawerContext.Provider value={{ collapse, isCollapsed }}>
            <Layout>
              <SessionTimeout />
              <BaseRoutes />
              
              
            </Layout>
          </sidebarDrawerContext.Provider>
        )}
      </Switch>
      </SongContext.Provider>
      </GoogleOAuthProvider>
    </>
  )
}

export default App
