import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../redux/track/actions"
import { actions as subscriptionAction } from "../../redux/subscription/actions"
import { actions as rewardsAction } from "../../redux/reward/actions"
import cogoToast from "cogo-toast"
import axios from "axios";
import { APIs } from "../../_helpers/apis"

const { createContext, useState, useCallback } = React

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const { auth,userInfo } = useSelector((state) => state.auth)
  const cart = useSelector((state) => state.track.cart || [])
  const dispatch = useDispatch()
  const [openLogin, setOpenLogin] = useState(false)
  const [openSignup, setOpenSignup] = useState(false)

  const handleLogin = useCallback(() => {
    setOpenLogin(!openLogin)
  }, [openLogin])

  const closeLogin = () => {
    setOpenLogin(false)
  }
  const updateSubscriptionCoins = () => {
    if(!userInfo && !(Object.keys(userInfo).length)) return
    // React.useEffect(() => {
      const getUserSubscription = async () => {
      try {
        
        const res = await axios.get(APIs.userSubscriptionPlan,{
          params:{user_id: userInfo.user_id}
        });
        dispatch(subscriptionAction.userSubscriptionPlan(res.data)) 
        // setUserSubscription(res.data)
      } catch (error) {
        console.log("error here",error)
      }
    }
      const getUserRewards = async () => {
      try {
        
        const res = await axios.get(`${APIs.userRewards}/${userInfo.user_id}`);
        dispatch(rewardsAction.userRewards(res.data)) 
        // setUserSubscription(res.data)
      } catch (error) {
        console.log("error here",error)
      }
    }
    Object.keys(userInfo).length && (getUserSubscription() )
    Object.keys(userInfo).length && (getUserRewards() )
    // },[userInfo])
  }

  const handleSignup = useCallback(() => {
    setOpenSignup(!openSignup)
  }, [openSignup])

  const handleCart = useCallback(
    (data) => {
      if (auth) {
        const exist = cart.find((c) => c.track_id === data.track_id)
        if (exist) {
          cogoToast.warn("already in cart")
        } else {
          dispatch(actions.addToCartRequest(data))
        }
      } else {
        handleSignup()
      }
    },
    [auth, cart, dispatch, handleSignup]
  )

  // const value = { openLogin: loginPopup, openSignup: signupPopup, handleLogin, handleSignup }
  const value = { openLogin, openSignup, handleLogin, handleSignup, handleCart, closeLogin ,updateSubscriptionCoins }
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}
