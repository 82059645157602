import * as React from "react"
import { Box, Button, makeStyles, Typography, CircularProgress } from "@material-ui/core"
import { InputField } from "../../../_warbls/components/Form/Input"
import { Column } from "../../../_warbls/components/Flex/Column"
import { useFormik } from "formik"
import { schema } from "./schema"
import { toAbsoluteUrl } from "../../../_helpers/toAbsoluteUrl"
import { Link, useHistory } from "react-router-dom"
import { formScript, initValues } from "./script"
import { actions } from "../../../redux/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import clsx from "clsx"
import md5 from "md5"
import { POST } from "../../../_helpers/fetcher"
import { APIs } from "../../../_helpers/apis"
import cogoToast from "cogo-toast"
import { useGoogleLogin } from '@react-oauth/google';
import axios from "axios"
import { Cancel, CheckCircle } from "@material-ui/icons"

export const Signin = ({ handleLogin, handleSignup }) => {
  const { button, login, policy, btn, disabled, inputTextClass,socialLogin,googleLogin,discordLogin,hrOr,hr,emailSection ,emailContinue,signupSection,signupBtn} = useStyles();
  const dispatch = useDispatch()
  const { loading } = useSelector((state) => state.auth)
  const DISCORD_REDIRECT_URL = 'https://warbls.com/discord-redirect';
  const DISCORD_CLIENT_ID = '1265976310975692842';
  const DISCORD_AUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&redirect_uri=${DISCORD_REDIRECT_URL}&response_type=code&scope=identify+email`;
  const [errors, setErrors] = React.useState(null)
  const [emailEl, setEmailEl] = React.useState(null)
  const history = useHistory()

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      try {
        dispatch(actions.loginRequest(values))
        const password = md5(values.password)
        const { data } = await POST(APIs.signin, { ...values, password })

        if (!data) {
          dispatch(actions.loginFailed())
          cogoToast.error("Wrong Email/Password")
          return
        } else {
          resetForm()
          handleLogin()
          dispatch(actions.loginSuccess(data, values.username));
          history.push("/vocals")
        }
      } catch (err) {
        dispatch(actions.loginFailed())
      }
      // "e10adc3949ba59abbe56e057f20f883e"e10adc3949ba59abbe56e057f20f883e
      // dispatch(actions.loginRequest(values))
    }
  })
  

  const handleAlreadyMember = () => {
    handleSignup()
    handleLogin()
  }
  if (errors && errors === "email already exists" && !!!emailEl) {
    cogoToast.error("Email already exists")
    // setEmailEl(document.getElementsByName("email")[0])
  }
  const GoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            'Authorization': `Bearer ${tokenResponse.access_token}`
          }
        });
        formik.setValues(({
          full_name: userInfo.data.name,
          social_login: true,
          social_id: userInfo.data.sub,
          email: userInfo.data.email,
          profile_img: userInfo.data.picture,
          username: userInfo.data.email,
          password: userInfo.data.sub,
          social_platform: 'GOOGLE'
        }), false);
        formik.handleSubmit();

      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    
  });
  const handleDiscordLogin = () => {
    window.location.href = DISCORD_AUTH_URL;
  };

  return (
    (
      <>
      
      <Column width="100%" jc="cenetr" >
        <Box my={1}>
          <img height="65px" src={toAbsoluteUrl("/media/logo-sm-white.png")} alt="logo" />
          <h2>Sign in</h2>
          <h5>to continue to warbls</h5>
        </Box>
        <Box className={socialLogin} 
        // style={{display:'flex',justifyContent:'center',alignItems:'center',gap: '15px'}}
        >
          <Box className={googleLogin} 
          onClick={() => GoogleLogin()} 
          style={{display:'flex',justifyContent:'center',alignItems:'center',padding: '7px 10px',border: '1px solid #848383',borderRadius: '3px',fontSize: '12px',gap: '5px'}}
          >
            <img width='30px' src={toAbsoluteUrl("/media/google-icon.png")} alt="logo" />
            <span>Sign in with Google</span>
          </Box>
          <Box className={discordLogin}
            onClick={() => handleDiscordLogin()} 
          // style={{display:'flex',justifyContent:'center',alignItems:'center',padding: '7px 10px',border: '1px solid #848383',borderRadius: '3px',fontSize: '12px',gap: '5px'}}
          >
            <img width='30px' src={toAbsoluteUrl("/media/discord-icon.png")} alt="logo" />
            <span>Sign in with Discord</span>
          </Box> 
        </Box>
        <Box className={hrOr}>
          <div className={hr}></div>
          <div>or</div>
          <div className={hr}></div>
        </Box>
        <Box className={emailSection}>
            <InputField
              // key={index}
              // placeholder={field.placeholder}
              name='email'
              type='email'
              formik={formik}
              label='Email'
              classes={inputTextClass}
              isLightTheme={true}
              rootStyle={{
                margin: "1px 0px"
              }}
              inputIcon={
                errors &&
                errors === "email already exists" &&
                ('email' === "email" ? <Cancel style={{ color: "red" }}></Cancel> : <CheckCircle style={{ color: "#4caf50" }}></CheckCircle>)
              }
            />
            {/* <div className={emailContinue} onClick={continueSignup}>
              Continue
            </div> */}
  
        </Box>
        <Box className={signupSection}>    
        <InputField
            // placeholder={field.placeholder}
            name='password'
            type='password'
            formik={formik}
            label="password"
            classes={inputTextClass}
            isLightTheme={true}
            rootStyle={{
              margin: "1px 0px"
            }}
          /> 
          
          <div className={emailContinue} onClick={() => {
            formik.setValues(({...formik.values,username: formik.values.email}), false);
            formik.handleSubmit()
            }}>
              Continue
            </div>
          {/* <div className={signupBtn} style={{textAlign: 'center'}} onClick={() => {
            formik.setValues(({...formik.values,username: formik.values.email}), false);
            formik.handleSubmit()
            }} >
            {loading ? <CircularProgress size={13} color="inherit" style={{verticalAlign: 'sub'}}/> :'Sign up'}
          </div> */}
        </Box>
        <Box my={2} width="100%" display="flex">
          <Typography >By continuing. you accept our Privacy Policy and Terms of Use <a href="#" onClick={handleAlreadyMember} style={{color:'#4592dd'}}>Sign up</a> </Typography>
        </Box>
       
  
      </Column>
      </>
    )
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 37
  },
  inputTextClass: {
    //backgroundColor: theme.palette.primary.contrastText,
    //color: theme.palette.primary.main,
    // backgroundColor:'#EBEBEB',
    // borderRadius:'10px',
    // color:'black',
    fontWeight:'bold',
    borderColor: '#504f4f'
  },
  
  socialLogin:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '15px'
  },
  googleLogin:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px 10px',
    border: '1px solid #504f4f',
    borderRadius: '3px',
    fontSize: '12px',
    gap: '5px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#0000004d',
      boxShadow: '1px 1px 8px #000'

    }

  },
  discordLogin:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '7px 10px',
    border: '1px solid #504f4f',
    borderRadius: '3px',
    fontSize: '12px',
    gap: '5px',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#0000004d',
      boxShadow: '1px 1px 8px #000'

    }
  },
  hrOr:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '22px'
  },
  hr:{
    height: '1px',
    backgroundColor: '#504f4f',
    width: '100%'
  },
  emailSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    '& > :first-child': {
      // Your styles for the first child here
    height: '80px',

    }
  },
  signupSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    '& > :first-child': {
      // Your styles for the first child here
    height: '80px',

    }
  },
  emailContinue:{
    padding:'11.3px 8px',
    backgroundColor: '#504f4f',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#0000004d',
      // boxShadow: '1px 1px 8px #000'

    }
  },
  signupBtn:{
    padding:'11.3px 8px',
    backgroundColor: '#504f4f',
    cursor: 'pointer',
    width: '25%'
  },
  button: {
    backgroundColor: "#42DE29",
    color: "#202020",
    width: "100%",
    height: 37,
    "&:hover": {
      color: "#ffffff",
      backgroundColor: theme.palette.primary.main
    }
  },
  login: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    height: 30,
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main
    }
  },
  policy: {
    color: theme.palette.primary.contrastText,
    fontWeight: "bold",
    fontSize: 13
  },
  btn: {
    borderRadius: 5,
    textTransform: "none",
    padding: 10,
    fontStyle: "normal",
    fontWeight: 'bold',
    fontSize: 15
  },
  disabled: {
    color: "#828282",
    backgroundColor: "#A9A9A9"
  }
}))
