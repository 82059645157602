export const steps_detail =[
    {
        heading:'Invite friends',
        detail:'Refer friends to Warbls through email or your personal referral link.'
    },
    {
        heading:'They get a coins',
        detail:`When your referrals subscribe to Warbls, they'll get 100 bonus coins.`
    },
    {
        heading:'You get coins',
        detail:`Once they complete their order, you'll get 100 coins.`
    }
]