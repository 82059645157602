import React, { useEffect } from 'react'
import { ProfileContainer } from '../Profile/ProfileContainer'
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import './index.css'
import { FullScreenPopup } from '../../../_warbls/components/Popup/fullScreenPopup';
import { Signin } from '../Signin';
// import { Popup } from "../Popup"

import { AppContext } from "../../../_warbls/providers/AppProvider"
import axios from 'axios';
import { APIs } from '../../../_helpers/apis';
import { useParams } from "react-router-dom"
import { Signup } from '../Signup';
import { useHistory } from "react-router"
const { useContext, useState } = React
export const ReferralsFriend = () => {
    // const { userInfo } = useSelector((state) => state.auth);
    const [user, setUser] = useState()
    const { id } = useParams()
    const history = useHistory()
    const { userInfo } = useSelector((state) => state.auth)
    const { openLogin, handleLogin, openSignup, handleSignup, toolbar ,updateSubscriptionCoins} = useContext(AppContext)
    useEffect(async () => {
      console.log({id})
      try {
        const r = await axios.get(`${APIs.users}/${id}`)
        if (r.status === 200 && r.data) {
          setUser(r.data)
        }
          // setUserInfo(r.data.results[0])
       
      } catch (err) {

      }
    }, [id])
  return (
    <div className='referrals-con'>
    <div className='referrals'>
        <div>
            <Typography className='title' variant='h5'>
              You have been invited with a special offer
            </Typography>
            <p className='title2'>
              <strong> {user && user.full_name} </strong> invites you to warbls -- explore unique sounds and elevate your music career 
            </p>
            <p className='title3'>
              Sign up & subscribe to get <strong>100</strong> bonus coins
            </p>
            <button className='claim-btn' onClick={handleSignup}>
              Claim special offer
            </button>
        </div>
        <div className='img-container'>
          <img src={`${process.env.PUBLIC_URL}/images/coinsReferrals.png`}  alt="" />
        </div>
    </div>
    {/* <FullScreenPopup open={openLogin} handleClose={handleLogin}> */}
   
    <FullScreenPopup open={openSignup} handleClose={handleSignup}>
    
        <Signup handleSignup={handleSignup} handleLogin={handleLogin} referrerId={user && user.user_id}/>
      </FullScreenPopup>
      {/* </FullScreenPopup> */}
    </div>
  )
}

