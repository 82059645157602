import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Typography } from '@material-ui/core'

export const FullScreenPopup = ({ open, handleClose, children }) => {
  const classes = useStyles();

  return (
    <div>
     
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
         <>
         <div style={{marginTop: '70px'}}>
         <Typography className='title' variant='h5' style={{'fontWeight': '500',textAlign:'center'}}>
              Sign up & Subscribe now to get <strong>100</strong> bonus coins
            </Typography>
            <p style={{textAlign: 'center'}}>Roll the dice and unlock the Mystery boxes filled with industry-standard sounds and premium VST plugins</p>
            </div>
        <div className={classes.paper}>

        
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {children}
        </div>
        </>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    outline: "none",
    border: "0px",
    boxShadow: "none",
    "&:hover": {
      border: "0px",
      boxShadow: "none",
      outline: "0px"
    },
    "&:focus": {
      border: "0px",
      boxShadow: "none",
      outline: "0px"
    }
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: "#333333",
    border: "0px",
    boxShadow: "none",
    borderRadius: 10,
    padding: 40,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:hover": {
      border: "0px",
      boxShadow: "none",
      outline: "0px"
    },
    "&:focus": {
      border: "0px",
      boxShadow: "none",
      outline: "0px"
    },
    "@media (max-width:450px)": {
      width: 350,
      padding: 20
    }
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
