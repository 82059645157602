import * as React from "react"
import { makeStyles, Toolbar, IconButton, Badge, Box, Button, useMediaQuery, useTheme, Typography } from "@material-ui/core"
import { FaShoppingCart } from "react-icons/fa"
import clsx from "clsx"
import { HeaderList } from "./List"
import { toAbsoluteUrl } from "../../../_helpers/toAbsoluteUrl"
import { Popup } from "../Popup"
import { AppContext } from "../../../_warbls/providers/AppProvider"
import { Signin } from "../../../app/modules/Signin"
import { Signup } from "../../../app/modules/Signup"
import { useSelector } from "react-redux"
import { GiHamburgerMenu } from "react-icons/gi"
import { SvgInline } from "../Svg"
import { ClickAwayListener } from "@material-ui/core"
import { useHistory } from "react-router"
import { useDispatch } from "react-redux"
// import { actions } from "../../../redux/auth/actions"
import { actions } from "../../../redux/subscription/actions"
import axios from "axios";
import { APIs } from "../../../_helpers/apis"
import { FaCoins } from "react-icons/fa";
import { useEffect } from "react"

import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';

const { useContext, useState } = React

export const Header = ({ handleDrawerOpen, open, indexPage }) => {
  const classes = useStyles()
  const { openLogin, handleLogin, openSignup, handleSignup, toolbar ,updateSubscriptionCoins} = useContext(AppContext)
  const [menuOpen, setMenuOpen] = useState(false)
  const { auth,userInfo } = useSelector((state) => state.auth)
  const is_admin = useSelector((state) => state.auth.userInfo.is_admin)
  const {userSubscription} = useSelector((state) => state.subscriptions)
  const {userRewards} = useSelector((state) => state.rewards)
  const theme = useTheme()
  const media = useMediaQuery(theme.breakpoints.down("sm"))
  const { cart } = useSelector((state) => state.track)
  const history = useHistory()
  const dispatch = useDispatch()
  // const [userSubscription,setUserSubscription] = useState();
  let planid;
  

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen)
  }

  const handleNavigation = (to) => {
    setMenuOpen(!menuOpen)
    history.push(to)
  }
  
  


  useEffect(() => {
    updateSubscriptionCoins()
    
  }, [userInfo])
  
  const totalRewardsRemainingCoins = userRewards && userRewards.reduce((sum, entry) => sum + entry.remaining_coins, 0);

  return (
    <>
      <Toolbar className={toolbar}>
        <div className={classes.navbar}>
          <div
            style={{
              position: "absolute",
              left: "20px",
              zIndex: "100"
            }}
          >
            {media ? (
              <GiHamburgerMenu fontSize={30} color="#ffffff" className="pointer" onClick={handleMenuOpen} />
            ) : (
              <>
                {open ? (
                  <img
                    onClick={(e) => history.push("/")}
                    src={toAbsoluteUrl("/media/warbls-logo.png")}
                    height="55"
                    width="30"
                    className="pointer"
                    alt="logo"
                    style={{ marginRight: 30, marginLeft: -3, marginTop: 5 }}
                  />
                ) : (
                  <img
                    onClick={(e) => history.push("/")}
                    src={toAbsoluteUrl("/media/logo-sm-white.png")}
                    height="50"
                    className="pointer"
                    width="30"
                    alt="small logo"
                    style={{ marginRight: 165 }}
                  />
                )}
              </>
            )}
          </div>
          <div className={classes.centerFlex}>{!media && <HeaderList />}</div>
          <div style={{ position: "absolute", right: "0" }}>
            <div className={classes.grow} />
            {is_admin ? (
              ""
            ) : (
              <div className={classes.sectionDesktop}>
                {!auth && (
                  <>
                    <Box mx={0} display="flex" alignItems="center">
                      <Typography onClick={handleLogin} className={clsx(classes.login, classes.root)}>
                        Log in
                      </Typography>
                    </Box>
                    <Box mx={0} display="flex" alignItems="center">
                      <Button onClick={handleSignup} className={clsx(classes.btn, classes.signup, classes.root)}>
                        Sign up
                      </Button>
                    </Box>
                  </>
                )}

                {auth && (
                  <>
                    {/* <Box mx={1} display="flex" alignItems="center">
                      <div className={classes.divider}></div>
                    </Box> */}
                    <h3 onClick={() => { 
                      (userSubscription?.subscription_plan_id)? history.push('/manage-subscription'):history.push('/plans')
                      
                      }} style={{ cursor:'pointer',marginRight:'30px'}}><FaCoins /> &nbsp;{((userSubscription?.remaining_coins || 0) + ( +totalRewardsRemainingCoins || 0)) || 0}</h3>
                    {/* <IconButton aria-label="show 4 new mails" color="inherit" style={{ marginRight: "10px" }}>
                      <Badge badgeContent={cart && cart.length} color="secondary" onClick={(e) => (auth ? history.push("/Cart") : handleLogin())}>
                        <FaShoppingCart color="#ffffff" />
                      </Badge>
                    </IconButton> */}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Toolbar>

      {menuOpen && media && (
        <ClickAwayListener onClickAway={handleMenuOpen}>
          <Box
            style={{
              position: auth ? "fixed" : "absolute",
              top: 64,
              left: 0,
              backgroundColor: "#333333",
              // width: "100vw",
              height: "100%",
              zIndex: 2000,
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding:'0 50px',
              paddingBottom:'30px'
            }}
          >
            <Box display="flex" mt={3} flexDirection="column" style={{ rowGap: "15px", fontSize: "20px", height:'80%',justifyContent:'space-between' }}>
              <Box style={{display:'flex',flexDirection:'column',gap:'3rem',borderBottom:'2px solid gray',paddingBottom:'50%'}}>

              <Box  style={{display:'flex', gap:'10px',alignItems:"center"}}>
                <SvgInline src={toAbsoluteUrl("./media/play.svg")} classes={classes.menuIcon} w={30} h={30} />
                <h3 style={{ padding: "0", margin: "0", textAlign: "center" }} onClick={() => handleNavigation("/vocals")}>
                Vocals
                </h3>
              </Box>

              {auth && (
                <Box style={{display:'flex', gap:'10px',alignItems:"center"}}>

                  <SvgInline src={toAbsoluteUrl("./media/like-vector.svg")} classes={classes.menuIcon} w={30} h={30} />
                  <h3 style={{ padding: "0", margin: "0", textAlign: "center" }} onClick={() => handleNavigation("/likes")}>
                  Liked
                  </h3>
                </Box>
              )}

              {auth && (
                <Box style={{display:'flex', gap:'10px',alignItems:"center"}}>
                  <SvgInline src={toAbsoluteUrl("./media/download.svg")} classes={classes.menuIcon} w={30} h={30} />
                  <h3 style={{ padding: "0", margin: "0", textAlign: "center" }} onClick={() => handleNavigation("/downloads")}>
                  Downloads
                  </h3>
                </Box> 
              )}
              </Box>
                {auth && (
              <Box style={{display:'flex' , flexDirection:'column', gap: "30px",alignItems:"center"}}>
                <Box style={{display:'flex', gap:'10px',alignItems:"center"}}>
                  <LibraryMusicIcon style={{width:30,height:30,marginLeft:4}}/>
                  <h3 style={{ padding: "0", margin: "0", textAlign: "center" }} onClick={() => handleNavigation("/my-vocals")}>
                  My Songs
                  </h3>
                </Box> 
                <Box style={{display:'flex', gap:'10px',alignItems:"center"}}>
                  <SvgInline src={toAbsoluteUrl("./media/profile.svg")} classes={classes.menuIcon} w={30} h={30} />
                  <h3 style={{ padding: "0", margin: "0", textAlign: "center" }} onClick={() => handleNavigation("/profile")}>
                  My Profile
                  </h3>
                </Box> 
              </Box>
              )}                
                {/* <h4 style={{ padding: "0", margin: "0", textAlign: "center" }} onClick={() => handleNavigation("/q&a")}>
                  Q&A
                </h4> */}
          </Box>
            {/* <Box display="flex" ml={0} mt={3} mb={3} style={{ columnGap: "10px" }}>
              <SvgInline src={toAbsoluteUrl("./media/facebook.svg")} classes={classes.menuIcon} w={30} h={30} />
              <SvgInline src={toAbsoluteUrl("./media/twitter.svg")} classes={classes.menuIcon} w={30} h={30} />
              <SvgInline src={toAbsoluteUrl("./media/youtube.svg")} classes={classes.menuIcon} w={30} h={30} />
              <SvgInline src={toAbsoluteUrl("./media/insta.svg")} classes={classes.menuIcon} w={30} h={30} />
            </Box> */}

            {/* {auth && (
              <Box
                display="flex"
                mt={0}
                flexDirection="column"
                style={{ rowGap: "15px", fontSize: "20px" }}
                onClick={() => {
                  dispatch(actions.logoutRequest())
                  history.push("/")
                }}
              >
                <h2 style={{ padding: "0", margin: "0" }}>Logout</h2>{" "}
              </Box>
            )} */}
          </Box>
        </ClickAwayListener>
      )}
      <Popup open={openLogin} handleClose={handleLogin}>
        <Signin handleSignup={handleSignup} handleLogin={handleLogin} />
      </Popup>
      <Popup open={openSignup} handleClose={handleSignup}>
        <Signup handleSignup={handleSignup} handleLogin={handleLogin} />
      </Popup>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    textTransform: "none",
    paddingLeft: 20,
    paddingRight: 20,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 15
  },
  centerFlex: {
    position: "absolute",
    inset: "0",
    display: "flex",
    justifyContent: "center"
  },
  grow: {
    flexGrow: 1
  },
  inputRoot: {
    color: "inherit"
  },
  sectionDesktop: {
    // display: "none",
    display: "flex",
    // width: "320px",
    // alignItems: "center",

    "@media (min-width:1200px)": {
      marginRight: "65px"
    },

    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  btn: {
    width: 107,
    height: 42,
    [theme.breakpoints.down("sm")]: {
      width: 120,
      height: 37,
      fontSize: 14
    }
  },
  login: {
    //backgroundColor: "#ffffff",
    color: theme.palette.primary.contrastText,
    cursor: "pointer"
    // "&:hover": {
    //   backgroundColor: "rgba(196, 196, 196, 0.6)",
    //   color: "#ffffff"
    // }
  },
  signup: {
    // backgroundColor:'#333333',
    backgroundColor: theme.palette.primary.contrastText,
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginRight: 20,
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
      boxShadow: '1px 1px 8px #fff'
    }
  },
  divider: {
    width: "1px",
    backgroundColor: theme.palette.secondary.main,
    height: "80%"
  },
  headerText: {
    "@media (max-width:1235px) ": {
      display: "none"
    }
  },
  uploadBtn: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    borderRadius: 5,
    width: 127,
    height: 35,
    fontWeight: 400,
    fontSize: 14,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main
    },
    [theme.breakpoints.down("sm")]: {
      width: 160,
      height: 40
    }
  },
  menuIcon: {
    margin: "0px 5px"
  },
  toolbar: {
    minHeight: 64,
    "&.MuiToolbar-regular": {
      minHeight: 64,
      [theme.breakpoints.down("sm")]: {
        minHeight: 64
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: 64
      }
    }
  },
  navbar: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}))
