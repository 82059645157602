import * as Types from "./types"

const initState = {
  userRewards:[]
}

export const rewardsReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.USER_REWARDS:
      return {
        userRewards: action.payload,
      }

    

    default:
      return state
  }
}
